footer{
    background-color: var(--bg-blue);    
    color:white;
    
}

.footer-top{
    padding: 2rem;

}

.bottom-left-container button{
    background-color: white;
    color: var(--bg-blue);
}


.bottom-left-container {
    line-height: 2rem;
    font-size: 1.2rem;
}

.footer-navigation 
,.footer-contact
,.footer-termes {
    margin-block: 1.5rem;
}
.footer-navigation p
,.footer-contact p
,.footer-termes p
{
    text-transform: uppercase;
    letter-spacing: .3rem;
    text-decoration: underline;
    font-weight: 900;
}

.footer-navigation li
,.footer-contact li
,.footer-termes li
{
    padding-block: 1rem;
    margin-left: 1rem;
}

.footer-navigation li a
,.footer-contact li a
,.footer-termes li a
{
    color:white;
    list-style: none;
    text-decoration: none;
    
}


.support-flex > div{
    margin-bottom: .4rem;
}

.footer-bottom{
    text-align: center;
    /* padding-inline:2rem ; */
}

.footer-bottom Phone{
    color: white;
    
}

@media screen and (min-width:940px){   
    
    
    .footer-top{
        display: flex;    
        justify-content: space-between;
        gap:2rem;
    }  

    .footer-contact
    ,.footer-navigation
    ,.footer-left
    ,.footer-termes
    {
        flex:1;
    }

    .footer-contact{
        margin: 0 left;
    }

    
    .footer-bottom{
    text-align: center;
    padding-block:2rem ;
    }

}

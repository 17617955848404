/* .section-container{
    margin-block:2rem ;
    border: none;
} */

.faq-outer-container{
    max-width: 760px; 
    margin: auto;
    margin-top: 2rem;
}




.faq-container .faq-question{
    font-weight: bold;
    
}


.faq-wrapper li{
    list-style: none;
    text-decoration: none;
}



.categ-container > label
,.categ-container > label ~ div
{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    margin: 0;
    padding: 1rem;
    width: 100%;
    text-align: start;
    font-weight: 600;
        
}

.categ-container{
    border-bottom: .01rem solid darkgray;
    border-left: .01rem solid darkgray;
    border-right: .01rem solid darkgray;
}


.referral {
    border-bottom: .02rem solid darkgray;

}



.questionAnswer-wrapper li p:first-child{
    font-weight: bold;
}

.faq-wrapper input[type=checkbox]{    
   display: none;
   
}

.categ-container > label{
    cursor: pointer;  
}

.categ-container > label ~ div{
    float: right;  
    width: 2%;
}

.categ-container{
    display: flex;
    align-items: center;
}


.first-row{    
    border-top: .01rem solid darkgray;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.referral{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}

.faq-answer-container{
    padding-inline: 1rem;
    margin: 0;

}



.more-help-container{
    margin: 2rem auto;
    width: 100%;
    border: .01rem solid darkgray;
    padding-block: 2rem;
    border-radius: 1rem;
}

.more-help-container p{
    margin: 0;
    text-align: center;
}

.more-help-container > p:first-child{
    font-weight: 900;
    font-size:1.2rem;
}

.more-help-container  > p:nth-child(2){
    margin-bottom: 1rem;

}

.box-to-contact{   
    margin: 0 auto;
    width: auto;
    max-width: 18rem;    
    display: flex;
    flex-direction: column;
    align-items: center;
}

.faq-answer-container{
    background-color: var(--bg-color-section);
    transition: height 2s;
    
}

.questionAnswer-wrapper li{
    padding-block: 1rem;
}




.cleaning-option-container .ads-btn-link {    
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    align-items: baseline;
    background-color: var(--bg-color-pages);
    border: .1rem solid var(--bg-blue);    
    padding: 1rem;
    margin-block: 1rem;
}

.btn .btn-blue{
    background-color: var(--bg-blue);  
    color: var(--bg-color-pages);
}

.ads-btn-link button{
    background-color: transparent;
    border: none;
    outline:none;
    cursor: pointer;
    color: var(--bg-blue);
    font-size: 1rem;
    font-weight: 800;
}

.ads-btn-link button:active{
    background-color: var(--bg-blue);
    color: var(--bg-color-pages);
}
.clean-list li{
    list-style: none;
    line-height: 2.5rem;
}

.clean-list li::before{
    content: '\2713';
    margin-right: 1rem;
    background-color: var(--bg-blue);
    border-radius: 50%;
    padding: .3rem;
    color: white;
    font-weight: 900;    
}

.ads-content{
    padding-inline: 1rem;
}

.clean-list > div{
    width: 10rem;
}


.clean-list .deep{
    font-weight: 800;
    color: var(--bg-blue);
}


.clean-list div:nth-child(2){
    width: 100%;
    display: flex;
    align-items: center;
}

.clean-list h2:last-child{
    background-color: var(--bg-blue);
    color: var(--bg-color-pages);
    padding: 1rem;
    margin-block: 1rem;
    width: fit-content;
}


/* 

 @media screen and (min-width:870px){   

    

    
 }  */
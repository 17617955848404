/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */


@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,700&display=swap');

:root {
  --bg-color-pages: #fafaf5;
  --bg-color-intro: #FBFBF9;
  --bg-color-section:#EAEEE9;
  --bg-blue: #011f4b;
  --darkgray-line: darkgray;
  --var-white:white;
}


body{
    background-color: var(--bg-color-pages);
    font-family: 'Roboto', sans-serif;    
    font-size: 16px;
    margin: 0;
    overflow: auto;
    
}

h1{
  font-size: 2.2rem;
}

/* .blue-background,
#btn-background,
footer{
  background-color: var(--bg-blue);
  
} */

.page-container{
  padding-block: .5rem;
}

.section-container{
  line-height: 2rem;
}

.logo-container_header
,.logo-container_footer{
  max-width: 8rem;
  min-width: 6rem;
}

img{
max-width: 100%;
width: 100%;
height: auto;
}

.btn-toggle{
  width: 100%;
  background-color: transparent;
  text-align: start;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
}


/* .content-container{
  max-width: 900px;
  margin: 0 auto;
} */


@media screen and (min-width:940px){   
  .content-container{
    max-width: 1000px;
    margin: 0 auto;
  }
  page-container{
    padding-block: 1rem;
    max-width: 1000px;
    margin: 0 auto;
  }
  
}
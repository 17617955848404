

.benefit-section
,.contact-section
{
    background-color: var(--bg-color-section);
    box-shadow: 0 0 .4rem var(--darkgray-line);
    border-top-left-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

.benefit-section{
    padding-inline: 1.5rem;
    margin-block: 2rem;
}

.content-container{
    padding-inline: 1rem;
}

 .section-ads h2{
    font-size: 1.4rem;
    font-weight: 800;
} 

.benefit-section h2,
.faq-section h2,
.contact-section > h2{
    text-align: left;
    font-size: 1.5rem;
    font-weight: 800;
}


.form-section-header > div{
    top:-1rem;
    color: var(--bg-color-intro);
    background-color: var(--bg-blue);
    width: 40%;
    padding: .01rem;
}


.benefit-section,
.faq-section{
        padding-block: 4rem;
    }


@media screen and (min-width:940px){   
    .contact-section,
    .benefit-section,
    .faq-section,
    .section-ads
    {
        padding: 2rem;
    }
        
  }
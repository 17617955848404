

.booking-page{
    margin: 0 auto;

}

.booking-header{
    background-color: var(--bg-blue);
    padding-block: 10rem;
    padding-inline: 2rem;
}


.booking-header h1{
    text-align: center;
    color: var(--bg-color-pages);
    font-size: 2.2rem;
}


.booking-page-left 
.booking-page-right
{
    margin-block: 4rem;
}

.booking-page-right{
    display: none;
}

.booking-page-left form{
    width: 100%;
    border: .1rem solid var(--darkgray-line);
    box-shadow:  0 0 .4rem var(--darkgray-line);
    padding: 1rem 1.5rem;
    border-radius: 1rem;
}

.booking-page-left form
,.booking-page-right .booking-page-right-container
{
    border: .1rem solid var(--darkgray-line);
    box-shadow:  0 0 .4rem var(--darkgray-line);    
}

form section{
    margin-bottom: 2.4rem;
    border-bottom: .1rem solid var(--darkgray-line);
}

form section h3{
    font-weight: 900;
    margin-bottom: 1rem;
    text-transform: uppercase;
}



.booking-page-left form input[type='text']
,.booking-page-left form input[type='email']
,.booking-page-left form input[type='date']
,.booking-page-left form input[type='number']
,.booking-page-left form select{
    width: 100%;
    /* margin-bottom: 1rem; */
    margin-top: .4rem;
    padding: .4rem;
    border: none;
    outline: none;
    box-shadow: 0 0 .1rem  navy;
    border-radius: .5rem;
}




.booking-page-left form button{
    width: 100%;
    outline: none;
    border: none;
    border-radius: 1rem;
    padding-block: 1rem;
    color: white;
    background-color: var(--bg-blue);
    margin-block: 1rem;
}


.booking-page-left form .errorContainer{
    color: red;
}

form button{
    cursor: pointer;
    font-size:1.1rem;
}

.cleaning-detail p{
    margin-top: 1rem;
}

@media screen and (min-width:940px){
    
    .booking-body{
        display: flex;
        gap: .6rem;
        justify-content: space-between;
    }
    .booking-page-right{
        display: block;
    }

    .booking-body{
        max-width:85% ;
        margin: auto;
    }
    .booking-page-left
    ,.booking-page-right{
        flex: 1;
    }

    
    
    .booking-page-left form
    ,.booking-page-right .booking-page-right-container
    {
        background-color: white;
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        padding: 2rem 1rem;
        align-self: stretch;
        
    }

    .booking-page-right .booking-page-right-container{
        background-color: white;       
    
    }

    .booking-page-right-container p{
        font-size:1.2rem;        
        padding-block:1rem;
    }


    .booking-page-right-container p:nth-child(2){
        font-weight: 900;
        font-size: 1.4rem;
        text-align: center;
        padding-block: 10ren;
    }

    .booking-page-left 
    ,.booking-page-right
    {
        position: relative;
        top:-7rem;
        /* background-color: white; */
    
    }

}
 .contact-component-container{
    margin: 2rem 1rem;
    padding-block: 2.4rem;
}


.contact_right_container{
    box-shadow:  0 0 .4rem var(--darkgray-line);
    margin-block: 2rem;
    box-sizing: border-box;
    padding: 2rem;
    background-color: var( --var-white );
    border-radius: 1rem;
}

.contact-form-container input
,.contact-form-container textarea
{
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: .2rem;
    outline: none;
    border: none;
    box-shadow: 0 0 .3rem var(--darkgray-line);
    margin-top: .2rem;
}

.contact-form-container .error{
    color:red;
}
 


.contact-flex{
    display: flex;
    align-items: center;
}

.contact-flex p,
.contact-flex div
{
    margin-left: .5rem;
}

.phone-contact-wrapper{
    padding: 0;
    width: fit-content;
}
 
.contact_left_container h1{
    font-size: 1.6rem;
    font-weight: 900;
    margin-bottom: 1rem;
}

.contact_left_container  .contact-flex{
    font-size: 1.3rem;
    line-height: 2.4rem;
} 




@media screen and (min-width:870px){ 
    .contact-component-container{
        display: flex;
        justify-content: space-between;
        align-items: center;    
        padding-inline: 1rem;
    }

    .contact_right_container,
    .contact_left_container
    {
        flex: 1;
        padding: 2rem;
        margin-block: 1rem;
        width: 100%;
        
    }

}



.phone-container
{
    font-size: 1.3rem;
    padding: 0;
    color: black;

}


.phone-container a{
    list-style-type: none;
    list-style: none;
    text-decoration: none;
    /* text-align: center; */
    font-weight: bold;
    padding: 0;
}

.light{
    color: white;
}

.dark{
    color: var(--bg-blue);
}





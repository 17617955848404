.button button{
    background-color: var(--bg-blue);
    color: white;
    height: 4rem;
    font-weight: bolder;
    border-radius: .4rem;
    /* box-shadow: .5rem .5rem 2px 1px rgba(227, 160, 46, 0.3); */
    font-size: 1rem;
    letter-spacing: .2rem;
    cursor:pointer;
    margin: 1rem 0;
    padding: 1rem;
    box-sizing: border-box;
}

.main-btn{
    width: 100%;
}

.small-btn{
    width: 40vw ;
}

.submit-btn{
    width:auto
}

.main-btn:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

.main-message h1{
    text-align: left;
    line-height: 3.2rem;
    font-size: 2rem;
    font-weight: 800;
    
}

.hero-right img{
    box-shadow: 0.3rem 0.4rem .4rem var(--bg-blue);
}

.secondary-message p{
    font-size: large;
    line-height: 2rem;
}


@media screen and (min-width:940px){   
    .hero-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap:1rem;
    }

    .hero-right,
    .hero-left
    {
        flex-basis: 0;
        flex: 1;
    }
}
.form-input {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .form-input input {
    transition: 0.3s all ease;
    background-color: transparent;
    color: #111;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border: 1px solid #cccccc;
    padding: 10px;
  }
  
  .form-input input:focus-visible {
    transition: 0.3s all ease;
    outline: none;
    border: 1px solid rgb(47, 77, 131);
  }
  
  
  
 
  
  input:invalid[focused="true"] {
    border: 1px solid #ff0000;
  }

  .field-container p{
    color: red;
  }

  .field-container {
    padding: .5rem 0;
    margin-block: .3rem;
    /* background-color: aqua; */
    /* height: 3rem; */
  }
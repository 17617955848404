 .radio-container{
  width: 100%; 
  height: auto;
  margin-bottom: 1rem;
}


  

.radio-container input[type="radio"] {
    appearance: none;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
    border: .1rem solid var(--bg-blue);
    transition: 0.2s all linear;
    margin-right: 5px;
    position: relative;
    top: 4px;
    
  }

  .radio-container input[type="radio"]:checked {
    border: 6px solid black;
  }
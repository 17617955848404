/* .App{
  padding: 0 1rem ;
  color: darkgray;
  font-size: 1rem;
} */

.App{
  overflow:auto;
  margin:0;
  padding: 0;
}
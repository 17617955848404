.residential > div{
    /* border-top: .1rem solid darkgray; */
    padding: 1rem;
}



.work-container{
    box-shadow: 0.3rem 0.3rem .4rem var(--darkgray-line);
    background-color: var(--bg-color-pages);
    border:none;
    outline: none;
    border-radius: 1rem;
    margin-block:2rem ;
    
}


.why-desc-container h4{
    font-weight: 900;
    font-size: 1.3rem;
}

.why-desc-container p{
    font-size: 1rem;
    line-height: 2rem;
}
.linkContainer{
    background-color: var(--bg-blue);    
    line-height: 2.1rem;
    font-weight: bolder;
    border-radius: .4rem;
    font-size: 1.2rem;
    letter-spacing: .2rem;
    cursor:pointer;
    margin: 1rem 0;
    padding: 1rem;
    text-align: center;
    width: 100%;
}

.linkContainer a{
    color:white;
    text-decoration: none;
}

